var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"ant-pagination ant-table-pagination"},[_c('li',{staticClass:"ant-pagination-total-text"},[_vm._v("共 "+_vm._s(_vm.total)+" 条数据")]),_c('li',{class:{
        'ant-pagination-prev': true,
        'ant-pagination-disabled': !_vm.prevEnabled
      },attrs:{"title":"上一页"},on:{"click":() => _vm.prevEnabled && this.moveBackward()}},[_c('a',{staticClass:"ant-pagination-item-link"},[_c('i',{staticClass:"anticon anticon-left",attrs:{"aria-label":"图标: left"}},[_c('left-arrow')],1)])]),_c('li',{staticClass:"ant-pagination-item ant-pagination-item-1 ant-pagination-item-active",attrs:{"tabindex":"0"}},[_c('a',[_vm._v(_vm._s(_vm.page))])]),_c('li',{class:{
        'ant-pagination-next': true,
        'ant-pagination-disabled': !_vm.nextEnabled
      },attrs:{"title":"下一页","tabindex":"0"},on:{"click":() => _vm.nextEnabled && this.moveForward()}},[_c('a',{staticClass:"ant-pagination-item-link"},[_c('i',{staticClass:"anticon anticon-right",attrs:{"aria-label":"图标: right"}},[_c('right-arrow')],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }