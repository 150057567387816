<template>
  <div>
    <ul class="ant-pagination ant-table-pagination">
      <li class="ant-pagination-total-text">共 {{ total }} 条数据</li>
      <li
        title="上一页"
        v-bind:class="{
          'ant-pagination-prev': true,
          'ant-pagination-disabled': !prevEnabled
        }"
        @click="() => prevEnabled && this.moveBackward()"
      >
        <a class="ant-pagination-item-link">
          <i aria-label="图标: left" class="anticon anticon-left">
            <left-arrow />
          </i>
        </a>
      </li>
      <li
        tabindex="0"
        class="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active"
      >
        <a>{{ page }}</a>
      </li>
      <li
        title="下一页"
        tabindex="0"
        v-bind:class="{
          'ant-pagination-next': true,
          'ant-pagination-disabled': !nextEnabled
        }"
        @click="() => nextEnabled && this.moveForward()"
      >
        <a class="ant-pagination-item-link">
          <i aria-label="图标: right" class="anticon anticon-right">
            <right-arrow />
          </i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import LeftArrow from "../../assets/left-arrow.svg";
import RightArrow from "../../assets/right-arrow.svg";

export default {
  name: "TokenPagination",
  props: ["total", "page", "pageSize", "nextToken"],
  components: {
    LeftArrow,
    RightArrow
  },
  computed: {
    prevEnabled: function() {
      return this.page > 1;
    },
    nextEnabled: function() {
      if (this.nextToken != null && this.nextToken.length > 0){
        return true;
      } else {
        return this.total / this.pageSize - this.page > 0;
      }
    }
  },
  methods: {
    moveForward: function() {
      this.$emit("change", this.page + 1);
    },
    moveBackward: function() {
      this.$emit("change", this.page - 1);
    }
  }
};
</script>
